export default {
  loading: {
    status: false,
    opacity: 1,
    message: "",
    initial: false,
    overlay: false
  },
  navigation: {
    drawerOpen: null
  },
  notifications: {
    snackbar: {
      active: false,
      timeout: 5000,
      message: null,
      attrs: {}
    }
  }
};
