<template>
  <div>
    <Page title="Dashboard">
      <DashboardShortcuts />
    </Page>
    <v-container fluid>
      <v-row>
        <v-col cols="12" sm="4">
          <TestingStatistics />
        </v-col>
        <v-col cols="12" sm="8">
          <DashGraph />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Page from "@/components/layout/Page";
import DashboardShortcuts from "@/components/dashboard/DashboardShortcuts";
import TestingStatistics from "@/components/dashboard/TestingStatistics";
import DashGraph from "@/components/dashboard/DashGraph";

export default {
  name: "Home",
  components: {
    DashGraph,
    TestingStatistics,
    Page,
    DashboardShortcuts
  }
};
</script>
