<template>
  <v-card min-height="200px">
    <v-card-title v-if="pageTitle !== false">
      {{ pageTitle }}
    </v-card-title>

    <v-divider></v-divider>

    <v-container>
      <slot></slot>
    </v-container>
  </v-card>
</template>

<script>
import hasIn from "lodash.hasin";

export default {
  name: "Page",
  props: ["title"],
  computed: {
    pageTitle() {
      if (this.title === false) return false;
      if (this.title && this.title.length) return this.title;
      if (this.$router.currentRoute) {
        return hasIn(this.$router.currentRoute, "meta.title")
          ? this.$router.currentRoute.meta.title
          : this.$router.currentRoute.name;
      }
      return false;
    }
  }
};
</script>

<style scoped></style>
