<template>
  <v-row
    v-if="hide === undefined || hide === false || !!error"
    class="fill-height pa-5"
    align-content="center"
    justify="center"
  >
    <template v-if="!!error">
      <v-col cols="12" class="subtitle-1 text-center">
        <v-icon color="error" size="xx-large">
          {{ errorIcon }}
        </v-icon>
        <v-card-text v-html="error" class="text-body-1" />
      </v-col>
    </template>
    <template v-else>
      <v-col class="subtitle-1 text-center" cols="12">
        {{ loadingMessage || "Loading..." }}
      </v-col>
      <v-col :cols="cols">
        <v-progress-linear
          color="primary accent-4"
          v-model="value"
          :indeterminate="value === 0"
          rounded
          height="6"
        ></v-progress-linear>
      </v-col>
    </template>
  </v-row>
</template>

<script>
export default {
  name: "LoadingBar",
  props: {
    value: {
      default: 0
    },
    hide: {
      default: false
    },
    error: {
      default: false
    },
    loadingMessage: {
      default: null
    },
    errorIcon: {
      default: "mdi-alert-circle"
    },
    cols: {
      default: "6"
    }
  }
};
</script>

<style scoped></style>
