<template>
  <v-menu
    v-model="dateMenu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    max-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :label="datePickerConfig.label"
        append-icon="mdi-calendar-search"
        readonly
        :value="dateDisp | formatDate"
        v-on="on"
        @input="onClearClicked"
        :rules="rules"
        :clearable="clearable"
      >
      </v-text-field>
    </template>
    <v-date-picker
      locale="en-in"
      :min="datePickerConfig.minDate"
      :max="datePickerConfig.maxDate"
      v-model="calVal"
      no-title
      @input="dateMenu = false"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "DatePickerInput",
  props: {
    datePickerConfig: {},
    defaultValue: {},
    rules: {},
    clearable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      dateMenu: false,
      calVal: null,
      dateValue: null
    };
  },
  computed: {
    dateDisp() {
      return this.dateValue;
    }
  },
  watch: {
    calVal: {
      handler: function(newDate) {
        if (typeof newDate !== "undefined" && newDate !== null) {
          this.dateValue = newDate;
          this.$emit(
            "updateDateField",
            this.datePickerConfig.field,
            this.dateValue
          );
        }
      }
    }
  },
  methods: {
    // When cleared, trigger emit
    onClearClicked() {
      this.dateValue = "";
      this.$emit(
        "updateDateField",
        this.datePickerConfig.field,
        this.dateValue
      );
    }
  },
  filters: {
    formatDate: function(value) {
      if (value) {
        return dayjs(String(value)).format("MM/DD/YYYY");
      }
    }
  },
  mounted() {
    // If a default value exists, load it
    if (this.defaultValue) {
      this.dateValue = this.defaultValue;
    }
  }
};
</script>
