import Vue from "vue";
import { getField, updateField } from "vuex-map-fields";

export default {
  namespaced: true,
  state: {
    accounts: []
  },
  getters: {
    getField
  },
  mutations: {
    updateField,
    setAccounts(state, payload) {
      Vue.set(state, "accounts", payload);
    },
    updateAccountApproved(state, payload) {
      Vue.set(state.accounts, payload.index, payload.item);
    }
  },
  actions: {
    getThirdPartyBillingClients({ commit }) {
      return Vue.axios
        .get("/third_party_billing_accounts", {
          params: { manage_billing: 1 }
        })
        .then(({ data }) => {
          commit("setAccounts", data.data);
        });
    },
    updateApprovedModule({ commit, dispatch }, payload) {
      Vue.axios
        .put("/third_party_billing_accounts/" + payload.item.id, {
          approved: payload.approved
        })
        .then(() => {
          payload.item.approved = payload.approved;
          commit("updateAccountApproved", payload);
          // Display error message using snackbar
          dispatch(
            "notify",
            {
              active: true,
              message:
                "Approved status for " +
                payload.item.client_third_party.title +
                " has been successfully updated."
            },
            { root: true }
          );
        })
        .catch(() => {
          // Display error message using snackbar
          dispatch(
            "notify",
            {
              active: true,
              message:
                "Something went wrong. Approved status was not updated for " +
                payload.item.client_third_party.title +
                "."
            },
            { root: true }
          );
          return false;
        });
    }
  }
};
