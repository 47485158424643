import Vue from "vue";
import isEmpty from "lodash.isempty";
import hasIn from "lodash.hasin";
import { getField, updateField } from "vuex-map-fields";

import { $http } from "@/utils";

export default {
  namespaced: true,
  state: {
    username: "",
    password: "",
    remember_me: true,
    registration: {
      business_name: "",
      first_name: "",
      last_name: "",
      error: false
    },
    copyToRegistration: {
      error: false,
      data: {
        email: "",
        first_name: "",
        last_name: "",
        fax: "",
        uuid: ""
      }
    },
    user: {
      id: null,
      first_name: "",
      last_name: "",
      email: "",
      client_id: "",
      lab_id: ""
    },
    activeTab: null,
    error: false,
    message: false
  },
  getters: {
    getField,
    user() {
      return Vue.auth.user();
    },
    clientIds(state) {
      return state.user.clients.map(a => a.id);
    }
  },
  mutations: {
    updateField,
    SET_MESSAGE(state, val) {
      state.message = val ? val : false;
    },
    SET_ERROR(state, val) {
      state.error = val ? val : false;
    },
    SET_ERROR_REGISTRATION(state, val = undefined) {
      if (val === undefined) {
        // Default error message
        val =
          "There was an issue registering your account. <br />Please contact CVAS Admin Support for help";
      }

      state.registration.error = val;
    },
    CLEAR_PASSWORD(state) {
      state.password = "";
    },
    SET_USER_DATA(state, data) {
      state.user = data;
    },
    SET_USER_DATA_COPY_TO(state, data) {
      Vue.set(state.copyToRegistration, "data", data);
    },
    SET_ERROR_COPY_TO(state, val = undefined) {
      if (val === undefined) {
        // Default error message
        val = "There was an issue finding your registration information";
      }

      state.copyToRegistration.error = val;
    }
  },
  actions: {
    fetch(data) {
      return Vue.auth.fetch(data);
    },
    refresh(data) {
      return Vue.auth.refresh(data);
    },
    login({ state, commit, getters }, data = {}) {
      commit("SET_ERROR"); // Clear error before logging in
      commit("SET_MESSAGE"); // Clear messages
      return new Promise(resolve => {
        Vue.auth
          .login({
            data: {
              username: state.username,
              password: state.password
            },
            remember: state.remember_me,
            staySignedIn: state.remember_me
          })
          .then(res => {
            commit("CLEAR_PASSWORD");

            if (data.remember) {
              Vue.auth.remember(
                JSON.stringify({
                  name: getters.user.first_name
                })
              );
            }

            console.log("Logged In!");
            // Vue.router.push({
            //   name: ctx.getters.user.type + "-landing"
            // });

            resolve(res);
          })
          .catch(e => {
            console.error("login error", e);
            commit("CLEAR_PASSWORD");
            if (e.response && hasIn(e.response, "data.message")) {
              commit("SET_ERROR", e.response.data.message);
              return;
            }
            commit("SET_ERROR", "Incorrect username or password");
          });
      });
    },
    logout({ dispatch }) {
      dispatch("clearState", null, { root: true });
      return Vue.auth.logout();
    },
    requestPasswordReset(ctx, email) {
      return $http
        .post(`/users/password-reset`, { email })
        .then(({ data }) => (data.success ? true : data))
        .catch(e => {
          if (e.response && hasIn(e.response, "data")) return e.response.data;
          return {
            success: false,
            data: { message: "Network Error - Please try again" }
          };
        });
    },
    passwordReset(ctx, { token, password, password_confirm }) {
      return $http
        .post(`/users/password-reset/${token}`, {
          password,
          password_confirm
        })
        .then(({ data }) => (data.success ? true : data))
        .catch(e => {
          if (e.response && hasIn(e.response, "data")) return e.response.data;
          return {
            success: false,
            data: { message: "Network Error - Please try again" }
          };
        });
    },
    submitRegistration({ state, commit }) {
      const first_name =
        !!state.registration.first_name && !!state.registration.last_name
          ? state.registration.first_name
          : state.registration.business_name;

      const business_name =
        state.registration.business_name &&
        state.registration.business_name.length
          ? state.registration.business_name
          : `${state.registration.first_name} ${state.registration.last_name}`;

      return $http
        .post("/users/register", {
          username: state.username,
          email: state.username,
          password: state.password,
          first_name: first_name,
          last_name: state.registration.last_name,
          clients: [
            {
              title: business_name,
              first_name: state.registration.first_name,
              last_name: state.registration.last_name
            }
          ]
        })
        .then(res => {
          const { data } = res;
          commit("SET_ERROR_REGISTRATION", false); // Clear any errors
          if (data.success !== true) {
            commit(
              "SET_ERROR_REGISTRATION",
              data.message ? data.message : undefined
            );
          }
          return data.success === true;
        })
        .catch(e => {
          if (e.request && e.request.status) {
            if (e.request.status === 422) {
              // Check for error response data
              if (hasIn(e.response, "data.data.errors")) {
                // Map errors to a variable
                const { errors } = e.response.data.data;
                if (!isEmpty(errors)) {
                  // Check for duplicate account errors
                  if (hasIn(errors, "email") && !!errors.email._isUnique) {
                    commit(
                      "SET_ERROR_REGISTRATION",
                      "This is a duplicate address. <br />Please contact CVAS Admin Support for help"
                    );
                    return false;
                  }
                }
              }
            }
          }
          commit("SET_ERROR_REGISTRATION");
          return false;
        });
    },
    copyToFetch({ commit }, token) {
      commit("SET_ERROR_COPY_TO", false);
      return $http
        .get(`/copy-to/${token}`, {
          params: { registration: 1 }
        })
        .then(res => {
          const { data } = res;
          if (!data.success || !data.data) {
            commit("SET_ERROR_COPY_TO");
            return false;
          }
          commit("SET_USER_DATA_COPY_TO", data.data);
          return true;
        })
        .catch(e => {
          console.error(e);
          commit("SET_ERROR_COPY_TO");
          return false;
        });
    },
    copyToRegister({ state, commit, dispatch }, token) {
      const payload = {
        first_name: state.copyToRegistration.data.first_name,
        last_name: state.copyToRegistration.data.last_name,
        fax: state.copyToRegistration.data.fax,
        email: state.username,
        o_user: {
          email: state.username,
          username: state.username,
          password: state.password,
          first_name: state.copyToRegistration.data.first_name,
          last_name: state.copyToRegistration.data.last_name,
          active: true
        }
      };
      return $http
        .put(`/copy-to/${token}`, payload)
        .then(res => {
          const { data } = res;
          if (!data.success || !data.data) {
            commit(
              "SET_ERROR_COPY_TO",
              "There was an issue with your account registration."
            );
            return false;
          }
          return dispatch("login");
        })
        .catch(e => {
          console.error(e);
          commit(
            "SET_ERROR_COPY_TO",
            "There was an issue with your account registration."
          );
          return false;
        });
    }
  }
};
