import { getField, updateField } from "vuex-map-fields";
import { getPaginated } from "@/utils/api-utils";

const sdGroupsOnEachSide = 7;

export default {
  namespaced: true,
  state: {
    items: [],
    error: {}
  },
  getters: {
    getField,
    values(state) {
      return state.items
        .filter(
          item => item.result_value || !isNaN(parseFloat(item.result_string))
        )
        .map(item => {
          return item.result_value
            ? parseFloat(item.result_value)
            : parseFloat(item.result_string);
        });
    },
    count(state, getters) {
      return getters.values.length;
    },
    sum(state, getters) {
      return getters.values.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        0
      );
    },
    mean(state, getters) {
      if (getters.count === 0) return 0;
      return getters.sum / getters.count;
    },
    // standard deviation
    sd(state, getters) {
      if (getters.count === 0) return 0;
      return Math.sqrt(
        getters.values
          .map(x => Math.pow(x - getters.mean, 2))
          .reduce((a, b) => a + b) / getters.count
      );
    },
    ranges(state, getters) {
      let ranges = [],
        count,
        bottom,
        top;
      top = getters.mean;
      bottom = top - getters.sd;
      count = 0;
      while (count < sdGroupsOnEachSide) {
        const cnt = getters.values.filter(val => val >= bottom && val <= top)
          .length;
        const percent = getters.count > 0 ? (cnt / getters.count) * 100 : 0;
        ranges.unshift({
          bottom: bottom,
          top: top,
          count: cnt,
          percent: percent
        });
        top = bottom;
        bottom -= getters.sd;
        count++;
      }
      bottom = getters.mean;
      top = bottom + getters.sd;
      count = 0;
      while (count < sdGroupsOnEachSide) {
        const cnt = getters.values.filter(val => val >= bottom && val <= top)
          .length;
        const percent = getters.count > 0 ? (cnt / getters.count) * 100 : 0;
        ranges.push({
          bottom: bottom,
          top: top,
          count: cnt,
          percent: percent
        });
        bottom = top;
        top += getters.sd;
        count++;
      }
      return ranges;
    },
    chartData(state, getters) {
      let data = getters.ranges.map(range => {
        return [
          Math.round(range.bottom * 10) / 10 +
            "-" +
            Math.round(range.top * 10) / 10,
          range.percent,
          range.count
        ];
      });
      data.unshift(["Range", "%", "#"]);
      return data;
    }
  },
  mutations: {
    updateField,
    setItems(state, items) {
      state.items = Array.isArray(items) ? items : [];
    }
  },
  actions: {
    getPaginated: getPaginated("/sample-component-method-results"),
    getSampleComponentMethodResults({ dispatch, commit }, params = {}) {
      return dispatch("getPaginated", params)
        .then(({ data }) => {
          if (data.success && data.data) {
            commit("setItems", data.data);
          }
          return data.success;
        })
        .catch(() => {
          return false;
        });
    }
  }
};
