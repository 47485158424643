import VueMask from "v-mask";

const Masks = {
  phone: function(val) {
    const len = val.replace(" ext ", "").length;

    let result = [
      "(",
      /\d/,
      /\d/,
      /\d/,
      ")",
      " ",
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      /\d/
    ];

    if (len > 14) {
      result = [
        ...result,
        " ",
        "e",
        "x",
        "t",
        " ",
        /[A-Za-z0-9#*]/,
        /[A-Za-z0-9#*]/,
        /[A-Za-z0-9#*]/,
        /[A-Za-z0-9#*]/,
        /[A-Za-z0-9#*]/,
        /[A-Za-z0-9#*]/,
        /[A-Za-z0-9#*]/,
        /[A-Za-z0-9#*]/,
        /[A-Za-z0-9#*]/,
        /[A-Za-z0-9#*]/
      ];
    }
    return result;
  }
};

const Mask = {
  install: function(Vue) {
    if (this.installed) return;
    Vue.use(VueMask);
    Vue.prototype.$masks = Masks;
    this.installed = true;
  }
};

export default Mask;
