import Vue from "vue";
import { getField, updateField } from "vuex-map-fields";

const source = function() {
  return {
    id: null,
    farm_id: null,
    feed_class_id: null,
    feed_type_id: null,
    title: "",
    number: "",
    latitude: "",
    longitude: "",
    address_1: "",
    address_2: "",
    city: "",
    country_id: "",
    state_id: "",
    postal_code: "",
    seed_brand: "",
    seed_genetics: "",
    additives: "",
    date_of_planting: "",
    date_of_purchase: "",
    storage_structure: "",
    storage_dimensions: "",
    yield: "",
    estimated_use_date: "",
    notes: "",
    is_active: true,
    farms_sources_harvest_dates: [],
    copy_tos: []
  };
};

export default {
  namespaced: true,
  state: {
    items: [],
    detailItem: source(),
    error: {}
  },
  getters: {
    getField,
    getActiveItems(state) {
      return state.items.filter(item => item.is_active);
    }
  },
  mutations: {
    updateField,
    setItems(state, payload) {
      Vue.set(state, "items", payload);
    },
    setItem(state, payload) {
      const item = state.items.find(item => item.id === payload.id);
      if (item) {
        Object.assign(item, payload);
      }
    },
    setDetailItem(state, payload) {
      const omitProps = ["feed_type"];
      omitProps.forEach(function(prop) {
        delete payload[prop];
      });
      Vue.set(state, "detailItem", payload);
    },
    clearDetailItem(state) {
      Vue.set(state, "detailItem", source());
    },
    addItem(state, item) {
      state.items.push(item);
    }
  },
  actions: {
    getFarmSources({ commit }, farmId) {
      return Vue.axios.get(`/farms/${farmId}/sources`).then(({ data }) => {
        commit("setItems", data.data);
        return data.success || false;
      });
    },
    getFarmSource({ commit }, { farm_id, source_id }) {
      return Vue.axios
        .get(`/farms/${farm_id}/sources/${source_id}`)
        .then(({ data }) => {
          commit("setDetailItem", data.data);
          return data.success || false;
        });
    },
    addFarmSource(context, payload) {
      return Vue.axios
        .post(`/farms/${payload.farm_id}/sources/`, payload)
        .then(({ data }) => {
          context.commit("addItem", Object.assign(payload, data.data));
          return data.success || false;
        });
    },
    editFarmSource(context, payload) {
      return Vue.axios
        .put(`/farms/${payload.farm_id}/sources/${payload.id}`, payload)
        .then(({ data }) => {
          context.commit("setItem", payload);
          return data.success || false;
        });
    }
  }
};
