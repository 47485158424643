import loadRoute from "@/router/utils/loadRoute";
import Login from "@/views/Login";

export default [
  {
    path: "/register",
    name: "Register",
    component: loadRoute("Containers/BaseContainer"),
    meta: {
      auth: 2
    },
    children: [
      {
        path: "password-reset/:token?",
        name: "Password Reset",
        component: () =>
          import(
            /* webpackChunkName: "password-reset" */ "@/views/Auth/PasswordReset"
          ),
        meta: {
          auth: 2
        }
      },
      {
        path: "copy-to/:token",
        name: "Copy To Register",
        component: () =>
          import(
            /* webpackChunkName: "copy-registration" */ "@/views/CopyTo/Register"
          ),
        meta: {
          auth: false
        }
      }
    ]
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      auth: false
    }
  }
];
