import Vue from "vue";
import { updateField } from "vuex-map-fields";

export default {
  updateField,
  setLoading(
    state,
    {
      message,
      opacity = 1,
      opacityOnly = false,
      initial = false,
      overlay = false
    }
  ) {
    Vue.set(state.loading, "opacity", opacity);
    if (opacityOnly) {
      return true;
    }
    Vue.set(state.loading, "initial", initial);
    Vue.set(state.loading, "status", !!message);
    Vue.set(state.loading, "message", message || "");
    Vue.set(state.loading, "overlay", overlay);
  },
  activateSnackbar(state, notification) {
    const defaultAttrs = {
      color: "primary"
    };

    Vue.set(state.notifications, "snackbar", {
      ...state.notifications.snackbar,
      ...notification,
      attrs: {
        ...defaultAttrs,
        ...notification.attrs
      }
    });
  }
};
