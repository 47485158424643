<template>
  <v-menu offset-y :nudge-width="200" :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="warning" light v-bind="attrs" v-on="on">
        Dev Toolbox
      </v-btn>
    </template>
    <v-card>
      <v-card-title>Dev Toolbox</v-card-title>
      <ToolboxUser />
    </v-card>
  </v-menu>
</template>

<script>
import ToolboxUser from "@/components/_dev/ToolboxUser";

export default {
  name: "DevToolbox",
  components: { ToolboxUser }
};
</script>
