import Vue from "vue";

export default {
  namespaced: true,
  state: {
    items: [],
    error: {}
  },
  getters: {},
  mutations: {},
  actions: {
    getReports(context, payload) {
      const config = {
        params: {
          sample_ids: payload.sample_ids,
          render: "pdf"
        },
        responseType: "blob",
        transformRequest: [
          function(data, headers) {
            // Remove the content type headers so any type is accepted
            delete headers.common["Content-Type"];
            delete headers.common["Accept"];
            return data;
          }
        ]
      };
      return Vue.axios.get("/sample-pdf-reports/0", config).then(res => {
        // Return the PDF blob
        return res.data;
      });
    },
    getSummaryReport(context, payload) {
      const config = {
        params: {
          render: "pdf"
        },
        responseType: "blob",
        transformRequest: [
          function(data, headers) {
            // Remove the content type headers so any type is accepted
            delete headers.common["Content-Type"];
            delete headers.common["Accept"];
            return data;
          }
        ]
      };
      return Vue.axios.get(payload.url, config).then(res => {
        // Return the PDF blob
        return res.data;
      });
    },
    downloadReports({ dispatch }, payload) {
      // Fetch the report(s) content
      return dispatch("getReports", payload)
        .then(report => {
          // Trigger download of the file
          let a = document.createElement("a");
          a.href = URL.createObjectURL(report);
          // Set the download filename
          a.setAttribute(
            "download",
            "CVAS-Report-(" + payload.sample_ids.join("-") + ")"
          );
          a.click();
          // Cleanup
          setTimeout(() => a.remove(), 500);
        })
        .catch(function(error) {
          let error_message =
            "An error occurred while retrieving your report. ";
          if (error.response) {
            console.log(error.response.statusText);
            error_message =
              error_message +
              "(" +
              error.response.status +
              " " +
              error.response.statusText +
              ")";
          }

          // Display error message using snackbar
          dispatch(
            "notify",
            {
              active: true,
              message: error_message
            },
            { root: true }
          );
          return false;
        });
    },
    downloadSummaryReport({ dispatch }, payload) {
      // Fetch the report(s) content
      return dispatch("getSummaryReport", payload).then(report => {
        // Trigger download of the file
        let a = document.createElement("a");
        a.href = URL.createObjectURL(report);
        // Set the download filename
        a.setAttribute("download", "CVAS-Summary-Report");
        a.click();
        // Cleanup
        setTimeout(() => a.remove(), 500);
      });
    },
    getSampleReport(context, payload) {
      const config = {
        params: {
          render: "pdf"
        },
        responseType: "blob",
        transformRequest: [
          function(data, headers) {
            // Remove the content type headers so any type is accepted
            delete headers.common["Content-Type"];
            delete headers.common["Accept"];
            return data;
          }
        ]
      };
      return Vue.axios
        .get("/sample-pdf-reports/" + payload.id, config)
        .then(res => {
          // Return the PDF blob
          return res.data;
        });
    },
    downloadSampleReport({ dispatch }, payload) {
      // Fetch the report
      return dispatch("getSampleReport", payload).then(report => {
        // Trigger download of the file
        let a = document.createElement("a");
        a.href = URL.createObjectURL(report);
        // Set the download filename
        a.setAttribute("download", "SampleReport");
        a.click();
        // Cleanup
        setTimeout(() => a.remove(), 500);
      });
    }
  }
};
