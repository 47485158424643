var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isLoading)?_c('div',{staticClass:"mt-5"},[_c('v-progress-linear',{attrs:{"indeterminate":""}})],1):_c('v-card',{staticClass:"mt-5"},[_c('v-card-text',[_c('v-form',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-select',{attrs:{"label":"Farm","items":_vm.farms,"item-text":"title","item-value":"id","no-data-text":"No farms to select from","disabled":_vm.isChartLoading,"clearable":""},model:{value:(_vm.options.params.farm_id),callback:function ($$v) {_vm.$set(_vm.options.params, "farm_id", $$v)},expression:"options.params.farm_id"}})],1),_c('v-col',[_c('v-select',{attrs:{"label":"Feed Source","items":_vm.options.params.farm_id ? _vm.sources : [],"item-text":"title","item-value":"id","no-data-text":"No feed sources to select from","disabled":_vm.isChartLoading,"clearable":""},model:{value:(_vm.options.params.farm_source_id),callback:function ($$v) {_vm.$set(_vm.options.params, "farm_source_id", $$v)},expression:"options.params.farm_source_id"}})],1),_c('v-col',[_c('v-autocomplete',{attrs:{"label":"Feed Type","items":_vm.feed_types,"item-text":"title","item-value":"id","no-data-text":"No feed types to select from","disabled":_vm.isChartLoading,"clearable":""},model:{value:(_vm.options.params.feed_type_id),callback:function ($$v) {_vm.$set(_vm.options.params, "feed_type_id", $$v)},expression:"options.params.feed_type_id"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('DatePickerInput',{attrs:{"default-value":_vm.options.params.from_date,"datePickerConfig":{
              label: 'From Date',
              field: 'from_date'
            },"clearable":false},on:{"updateDateField":(field, val) => {
                _vm.options.params.from_date = val;
              }}})],1),_c('v-col',[_c('DatePickerInput',{attrs:{"default-value":_vm.options.params.to_date,"date-picker-config":{
              label: 'To Date',
              field: 'to_date'
            },"clearable":false},on:{"updateDateField":(field, val) => {
                _vm.options.params.to_date = val;
              }}})],1),_c('v-col',[_c('v-autocomplete',{attrs:{"label":"Component","items":_vm.test_components,"item-text":"title","item-value":"id","disabled":_vm.isChartLoading,"clearable":""},model:{value:(_vm.options.params.test_component_id),callback:function ($$v) {_vm.$set(_vm.options.params, "test_component_id", $$v)},expression:"options.params.test_component_id"}})],1)],1)],1)],1),(_vm.isChartLoading)?_c('div',[_c('v-progress-linear',{attrs:{"indeterminate":""}})],1):[_c('v-card-text',{staticClass:"py-0"},[_c('b',[_vm._v("Feed Type")]),_vm._v(" "+_vm._s(_vm.feedTypeTitle)+" ")]),_c('v-card-text',{staticClass:"py-0"},[_c('b',[_vm._v("# of Samples")]),_vm._v(" "+_vm._s(_vm.count)+" ")]),_c('v-card-text',{staticClass:"py-0"},[_c('b',[_vm._v("Average")]),_vm._v(" "+_vm._s(Math.round(_vm.mean * 100) / 100)+" ")]),_c('v-card-text',{staticClass:"py-0"},[_c('b',[_vm._v("Standard Deviation")]),_vm._v(" "+_vm._s(Math.round(_vm.sd * 100) / 100)+" ")]),_c('GChart',{attrs:{"type":"ColumnChart","data":_vm.chartData,"options":_vm.chartOptions,"create-chart":(el, google, type) => {
          this.chart = new google.visualization[type](el);
          return this.chart;
        }}}),_c('v-card-text',{staticClass:"text-right"},[(_vm.chartImageUrl)?_c('v-btn',{attrs:{"icon":"","href":_vm.chartImageUrl,"download":_vm.chartOptions.title}},[_c('v-icon',[_vm._v("mdi-download")])],1):_vm._e()],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }