import Vue from "vue";

export const getPaginated = endpoint => {
  return (
    ctx,
    {
      page = 1,
      itemsPerPage = 15,
      sortBy = [],
      sortDesc = [],
      params = {}
    } = {}
  ) => {
    let sortByField = sortDesc.length && sortDesc[0] === true ? "desc" : "asc";
    return Vue.axios
      .get(endpoint, {
        params: {
          limit: itemsPerPage,
          page,
          sort: sortBy.length ? sortBy[0] : null,
          direction: sortBy.length ? sortByField : null,
          ...params
        }
      })
      .then(res => {
        return res;
      })
      .catch(e => {
        return e;
      });
  };
};

export default {
  getPaginated
};
