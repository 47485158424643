export const createSampleCalibrate = (overrides = {}) => {
  return {
    name: "",
    herd: "",
    calibrate_number: "",
    sample_calibrates_ingredients_id: null,
    cutting: "",
    last_cut_days: "",
    ...overrides
  };
};

export const createSample = (overrides = {}) => {
  return {
    is_active: true,
    client_id: null,
    copy_to: [],
    lab_id: null,
    shipment_method_id: null,
    farm_id: null,

    // General Sample Information
    feed_type_id: null,
    description: "",
    is_preground: false,
    is_fresh: false,
    grind_size: "",
    dry_matter_method: "",
    sample_bag_barcode: "",
    is_sample_bag: false,
    sample_bag_id: "",
    grind_method: "",
    date_collected: "",
    date_harvested: "",
    cutting: null,
    is_linked: false,
    linked_samples: [],
    source_other: "",
    gv_file_number: "",
    gv_sales_rep_name: "",
    gv_sales_rep_number: "",
    wallensteins_req_id: "",

    // Packages
    sample_calibrate: createSampleCalibrate(),
    is_calibrate: false,
    is_best_mix: false,
    best_mix_farm_id: "",
    best_mix_request_id: "",
    is_grand_valley: false,
    grand_valley_file_number: "",
    grand_valley_dairy_specialist: "",

    // Test Components
    test_components: [],
    // sample_test_components: [],
    test_packages: [],

    // TODO: Remove excess fields
    certified_protocol: false,
    cutting_interval: "",
    date_planted: "",
    date_shipped: "",
    estimated_dry_matter: "",
    farm: {},
    farms_source_id: null,
    feed_type: {},
    id: null,
    is_certified: false,
    is_project: false,
    is_received: false,
    latitude: "",
    longitude: "",
    lot_size: "",
    memo: "",
    modified: "",
    number_of_cores_bales: "",
    po_number_id: null,
    processing: "",
    project_id: null,
    project_number: "",
    receiving_description: "",
    sample_nir_status_id: null,
    sample_status_id: null,
    sample_volume_id: null,
    sampler_name: "",
    sampling_device: "",
    shipment_id: null,
    shipment: {
      shipment_method_other: ""
    },
    shipments: [
      {
        id: null
      }
    ],
    shipment_tracking_number: "",
    source_location: "",
    source_name: "",
    storage_system_id: null,
    test_components_combined: "",
    treatment: "",
    weeks_in_storage: "",
    year: "",
    ...overrides
  };
};
