<template>
  <v-list>
    <v-list-item>
      <v-list-item-title class="text-h6">User</v-list-item-title>
    </v-list-item>
    <v-list-item two-line>
      <v-list-item-title>Role Spoofing</v-list-item-title>
      <v-list-item-action class="mr-16">
        <v-checkbox
          v-for="role in allRoles"
          :key="role"
          v-model="userRoles"
          :value="role"
          :label="role"
        ></v-checkbox>
      </v-list-item-action>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: "User",
  data() {
    return {
      allRoles: ["client", "new"],
      test: false
    };
  },
  computed: {
    userRoles: {
      get() {
        const user = this.$auth.user();
        return user.roles && user.roles.length ? user.roles : [];
      },
      set(val) {
        this.$auth.user({
          ...this.$auth.user(),
          roles: val
        });
      }
    }
  }
};
</script>

<style scoped></style>
