import Vue from "vue";
import { getField, updateField } from "vuex-map-fields";

export default {
  namespaced: true,
  state: {
    items: [],
    active_copy_to: {},
    communication_types_selected: []
  },
  getters: {
    getField,
    getById(state) {
      /**
       * Return an item by ID
       * @param {int} id - The ID of the item you want returned
       * @param {string} idField (optional) - The field used when filtering by ID
       */
      return (id, idField = "id") =>
        state.items.find(item => item[idField] === id) || {};
    },
    items(state) {
      return state.items;
    }
  },
  mutations: {
    updateField,
    setItems(state, payload) {
      Vue.set(state, "items", payload);
    },
    setItem(state, payload) {
      const item = state.items.find(item => item.uuid === payload.uuid);
      if (item) {
        Object.assign(item, payload);
      }
    },
    setActiveCopyTo(state, payload) {
      Vue.set(state, "active_copy_to", payload);
      // Set reporting preferences
      if (payload.communication_types) {
        // Update the communication types proxy field, compatible for checkboxes
        Vue.set(
          state,
          "communication_types_selected",
          state.active_copy_to.communication_types.map(item => item.id)
        );
      }
    }
  },
  actions: {
    invite({ rootGetters, dispatch }, email) {
      const client = rootGetters["clients/selectedClient"];
      return Vue.axios
        .post(`/copy-to`, {
          email,
          clients: [{ id: client.id }]
        })
        .then(res => {
          const { data } = res;
          // Reload Copy Tos
          dispatch("getClientsCopyTos");

          return data.data.id || false;
        })
        .catch(e => {
          console.error(e);
          return false;
        });
    },
    getClientsCopyTos({ rootGetters, commit }) {
      const client = rootGetters["clients/selectedClient"];
      if (client.id) {
        return Vue.axios
          .get(`/clients_copy_to`, {
            params: {
              client_id: client.id
            }
          })
          .then(res => {
            commit("setItems", res.data.data);
            const { data } = res;
            return data.success || false;
          })
          .catch(e => {
            console.error(e);
            return false;
          });
      }
    },
    getCopyTos({ commit }) {
      return Vue.axios.get("/copy-to").then(({ data }) => {
        commit("setItems", data.data);
        return data.success || false;
      });
    },
    editCopyTo({ commit }, payload) {
      return Vue.axios
        .put(`/copy-to/${payload.uuid}`, payload)
        .then(({ data }) => {
          commit("setItem", payload);
          return data.success || false;
        });
    },
    getActiveCopyTo({ commit }, payload) {
      return Vue.axios
        .get(`/copy-to/` + payload.uuid, payload)
        .then(({ data }) => {
          commit("setActiveCopyTo", data.data);
          return data.success || false;
        });
    },
    editActiveCopyTo({ state }) {
      Vue.set(
        state.active_copy_to,
        "communication_types",
        state.communication_types_selected.map(id => {
          return { id };
        })
      );
      return Vue.axios
        .put(`/copy-to/` + state.active_copy_to.uuid, state.active_copy_to)
        .then(({ data }) => {
          return data.success || false;
        });
    }
  }
};
