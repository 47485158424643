<template>
  <LoginComponent />
</template>

<script>
import LoginComponent from "@/components/auth/Login";
export default {
  name: "Login",
  components: { LoginComponent }
};
</script>

<style scoped></style>
