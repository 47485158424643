import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import { getBaseUrl } from "@/utils";

axios.defaults.baseURL = getBaseUrl();
Vue.use(VueAxios, axios);

export default {
  root: axios.defaults.baseURL
};
