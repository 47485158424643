import Vue from "vue";
import * as types from "./mutation-types";

/**
 * To dispatch an action from a Vue component, make sure to prepend you mapper with "baseData"
 * `...mapActions("baseData", ["getCountries"])`
 *
 * To commit mutations from a Vue component
 * `import { SET_STATIC_DATA } from "@/store/modules/baseData/mutation-types"`
 * `...mapMutations("baseData", [SET_STATIC_DATA])`
 */
export default {
  namespaced: true,
  state: {
    countries: {
      items: [],
      error: false,
      message: ""
    },
    regions: {
      items: [],
      error: false,
      message: ""
    },
    states: {
      items: [],
      error: false,
      message: ""
    },
    communication_types: {
      items: [],
      error: false,
      message: ""
    },
    clients_address_types: {
      items: [],
      error: false,
      message: ""
    },
    labs: {
      items: [],
      error: false,
      message: ""
    },
    locales: {
      items: [],
      error: false,
      message: ""
    },
    unit_types: {
      items: [],
      error: false,
      message: ""
    },
    test_components: {
      items: [],
      error: false,
      message: ""
    },
    component_categories: {
      items: [],
      error: false,
      message: ""
    },
    test_packages: {
      items: [],
      error: false,
      message: ""
    },
    shipment_methods: {
      items: [],
      error: false,
      message: ""
    },
    feed_types: {
      items: [],
      error: false,
      message: ""
    },
    feed_classes: {
      items: [],
      error: false,
      message: ""
    },
    sample_calibrates_ingredients: {
      items: [],
      error: false,
      message: ""
    },
    sample_statuses: {
      items: [],
      error: false,
      message: ""
    },
    report_layouts: {
      items: [],
      error: false,
      message: ""
    },
    report_templates: {
      items: [],
      error: false,
      message: ""
    },
    report_template_types: {
      items: [],
      error: false,
      message: ""
    },
    test_package_categories: {
      items: [],
      error: false,
      message: ""
    }
  },
  getters: {
    getById(state, getters) {
      /**
       * Return an item by ID
       * @param {string} getter - The getter you're plucking an item from
       * @param {int} id - The ID of the item you want returned
       * @param {string} idField (optional) - The field used when filtering by ID
       */
      return (getter, id, idField = "id") =>
        getters[getter].find(item => item[idField] === id) || {};
    },
    filterById(state, getters) {
      /**
       * Return items filtered by ID
       * @param {string} getter - The getter you're plucking an item from
       * @param {int} id - The ID of the item you want returned
       * @param {string} idField (optional) - The field used when filtering by ID
       */
      return (getter, id, idField = "id") =>
        getters[getter].filter(item => item[idField] === id) || [];
    },
    // We can provide a shortcut to countries, and return an empty array if it contains an error
    countries(state) {
      if (state.countries.error) return [];
      return state.countries.items;
    },
    regions(state) {
      if (state.regions.error) return [];
      return state.regions.items;
    },
    states(state) {
      if (state.states.error) return [];
      return state.states.items;
    },
    communication_types(state) {
      if (state.communication_types.error) return [];
      return state.communication_types.items;
    },
    clients_address_types(state) {
      if (state.clients_address_types.error) return [];
      return state.clients_address_types.items;
    },
    labs(state) {
      if (state.labs.error) return [];
      return state.labs.items;
    },
    activeLabs(state, getters) {
      return getters.labs.filter(lab => !!lab.is_active);
    },
    sortedActiveLabs(state, getters, rootState, rootGetters) {
      return getters.activeLabs.sort((a, b) => {
        const byClient =
          (rootGetters["clients/clientLabIds"].includes(b.id) ? 1 : 0) -
          (rootGetters["clients/clientLabIds"].includes(a.id) ? 1 : 0);
        if (byClient !== 0) return byClient;
        const byOrder = b.sort_order - a.sort_order;
        if (byOrder !== 0) return byOrder;
        return a.title.localeCompare(b.title);
      });
    },
    locales(state) {
      if (state.locales.error) return [];
      return state.locales.items;
    },
    unit_types(state) {
      if (state.unit_types.error) return [];
      return state.unit_types.items;
    },
    test_components(state) {
      if (state.test_components.error) return [];
      return state.test_components.items;
    },
    component_categories(state) {
      if (state.component_categories.error) return [];
      return state.component_categories.items;
    },
    test_packages(state) {
      if (state.test_packages.error) return [];
      return state.test_packages.items;
    },
    shipment_methods(state) {
      if (state.shipment_methods.error) return [];
      return state.shipment_methods.items;
    },
    feed_types(state) {
      if (state.feed_types.error) return [];
      return state.feed_types.items;
    },
    getQuickFeedTypes(state) {
      if (state.feed_types.error) return [];
      return state.feed_types.items.filter(item => item.show_in_quick_feed);
    },
    feed_classes(state) {
      if (state.feed_classes.error) return [];
      return state.feed_classes.items;
    },
    sample_calibrates_ingredients(state) {
      if (state.sample_calibrates_ingredients.error) return [];
      return state.sample_calibrates_ingredients.items;
    },
    sample_statuses(state) {
      if (state.sample_statuses.error) return [];
      return state.sample_statuses.items;
    },
    report_layouts(state) {
      if (state.report_layouts.error) return [];
      return state.report_layouts.items;
    },
    report_templates(state) {
      if (state.report_templates.error) return [];
      return state.report_templates.items;
    },
    report_template_types(state) {
      if (state.report_template_types.error) return [];
      return state.report_template_types.items;
    },
    test_package_categories(state) {
      if (state.test_package_categories.error) return [];
      return state.test_package_categories.items;
    },
    getInvoicedStatusId(state) {
      if (state.sample_statuses.error) return 0;
      const status = state.sample_statuses.items.find(
        item => item.title === "Invoiced"
      );
      return status && status.id ? status.id : 0;
    }
  },
  mutations: {
    [types.SET_STATIC_DATA](state, { type, data }) {
      // Freeze object, since this data is non-reactive (set and forget)
      state[type].items = Object.freeze(data);
    },
    [types.SET_ERROR_DATA](state, { type, message }) {
      // Determine if we're clearing the error, or setting an error
      const hasError = !!message; // If message was provided, hasError will be true
      Vue.set(state[type], "error", hasError);
      Vue.set(state[type], "message", hasError ? message : "");
    }
  },
  actions: {
    /**
     * Reusable API request method for static data within this module
     *
     * @param commit
     * @param state
     * @param type {string} URL path (/api/{type}). This is also used for the state property, but converts - to _
     * @param force {boolean} force fetching results, even if we already have the data
     * @returns {boolean|Promise<void>}
     */
    getData({ commit, state }, { type, force }) {
      // Keep original type string as URL path
      const url = type;
      // Make the type string object friendly for use as the state property
      type = type.replaceAll("-", "_");
      // If force was not enabled, and there are no errors
      if (force !== true && state[type].error === false) {
        // If we already have results, return early
        if (state[type].items.length) {
          return true;
        }
      }
      return Vue.axios
        .get(url)
        .then(res => {
          const { data } = res;
          commit(types.SET_ERROR_DATA, { type }); // Clear any errors
          commit(types.SET_STATIC_DATA, { type, data: data.data });
        })
        .catch(e => {
          commit(types.SET_ERROR_DATA, { type, message: e });
        });
    },
    getCountries({ dispatch }, force = false) {
      return dispatch("getData", { type: "countries", force });
    },
    getRegions({ dispatch }, force = false) {
      return dispatch("getData", { type: "regions", force });
    },
    getStates({ dispatch }, force = false) {
      return dispatch("getData", { type: "states", force });
    },
    getCommunicationTypes({ dispatch }, force = false) {
      return dispatch("getData", { type: "communication-types", force });
    },
    getClientsAddressTypes({ dispatch }, force = false) {
      return dispatch("getData", { type: "clients-address-types", force });
    },
    getLabs({ dispatch }, force = false) {
      return dispatch("getData", { type: "labs", force });
    },
    getLocales({ dispatch }, force = false) {
      return dispatch("getData", { type: "locales", force });
    },
    getUnitTypes({ dispatch }, force = false) {
      return dispatch("getData", { type: "unit-types", force });
    },
    getComponentCategories({ dispatch }, force = false) {
      return dispatch("getData", { type: "component-categories", force });
    },
    getTestComponents({ dispatch }, force = false) {
      return dispatch("getData", { type: "test-components", force });
    },
    getPackages({ dispatch }, force = false) {
      return dispatch("getData", { type: "test-packages", force });
    },
    getShippingMethods({ dispatch }, force = false) {
      return dispatch("getData", { type: "shipment-methods", force });
    },
    getFeedTypes({ dispatch }, force = false) {
      return dispatch("getData", { type: "feed-types", force });
    },
    getFeedClasses({ dispatch }, force = false) {
      return dispatch("getData", { type: "feed-classes", force });
    },
    getCalibrateIngredients({ dispatch }, force = false) {
      return dispatch("getData", {
        type: "sample-calibrates-ingredients",
        force
      });
    },
    getSampleStatuses({ dispatch }, force = false) {
      return dispatch("getData", { type: "sample-statuses", force });
    },
    getReportLayouts({ dispatch }, force = false) {
      return dispatch("getData", { type: "report-layouts", force });
    },
    getReportTemplates({ dispatch }, force = false) {
      return dispatch("getData", { type: "report-templates", force });
    },
    getReportTemplateTypes({ dispatch }, force = false) {
      return dispatch("getData", { type: "report-template-types", force });
    },
    getTestPackageCategories({ dispatch }, force = false) {
      return dispatch("getData", { type: "test-package-categories", force });
    },
    /**
     * Load base data
     * This gets called from "@/plugins/auth/drivers/jwt.js", which runs when the application starts (including page refreshes)
     *
     * Add `dispatch` items to the Promise.all array for each actions that should get run when the application starts.
     * To run actions outside of this module, see this page:
     * https://vuex.vuejs.org/guide/modules.html#accessing-global-assets-in-namespaced-modules
     *
     * @returns {Promise<unknown[]>}
     */
    load({ dispatch }) {
      //
      return Promise.all([dispatch("getCountries"), dispatch("getStates")]);
    }
  }
};
