<template>
  <div>
    <v-container>
      <v-btn
        color="primary"
        to="/samples/sample-wizard"
        @click="clearActive"
        class="ma-5"
      >
        Submit New Sample
      </v-btn>
      <v-btn color="primary" to="/samples/browse" class="ma-5">
        Samples Browse
      </v-btn>
      <v-btn color="primary" to="/farms/browse" class="ma-5">
        Farms Browse
      </v-btn>
      <v-btn color="primary" to="/copy-to/browse" class="ma-5">
        Recipients Browse
      </v-btn>
    </v-container>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "DashboardShortcuts",
  methods: {
    ...mapMutations("samples", ["clearActive"])
  }
};
</script>
