import { getField, updateField } from "vuex-map-fields";
import Vue from "vue";
import { getPaginated } from "@/utils/api-utils";

const csv = function() {
  return {
    id: null,
    name: "",
    description: ""
  };
};

export default {
  namespaced: true,
  state: {
    items: [],
    detailItem: csv(),
    error: {}
  },
  getters: {
    getField
  },
  mutations: {
    updateField,
    setItems(state, payload) {
      Vue.set(state, "items", payload);
    },
    setItem(state, payload) {
      const item = state.items.find(item => item.id === payload.id);
      if (item) {
        Object.assign(item, payload);
      }
    },
    deleteItem(state, id) {
      const idx = state.items.findIndex(item => item.id === id);
      if (idx >= 0) {
        const items = [...state.items];
        items.splice(idx, 1);
        Vue.set(state, "items", items);
      }
    },
    setDetailItem(state, payload) {
      Vue.set(state, "detailItem", payload);
    },
    clearDetailItem(state) {
      Vue.set(state, "detailItem", csv());
    },
    addItem(state, item) {
      state.items.push(item);
    }
  },
  actions: {
    getPaginated: getPaginated("/custom-csvs"),
    addCsv(context, payload) {
      return Vue.axios.post("/custom-csvs", payload).then(({ data }) => {
        context.commit("addItem", Object.assign(payload, data.data));
        return data.success;
      });
    },
    editCsv(context, payload) {
      return Vue.axios
        .put(`/custom-csvs/${payload.id}`, payload)
        .then(({ data }) => {
          context.commit("setItem", payload);
          return data.success;
        });
    },
    getCsvs({ commit, dispatch }) {
      return dispatch("getPaginated").then(({ data }) => {
        commit("setItems", data.data);
        return data.success;
      });
    },
    getCsv({ commit }, id) {
      return Vue.axios.get(`/custom-csvs/${id}`).then(({ data }) => {
        commit("setDetailItem", data.data);
        return data.success;
      });
    },
    deleteCsv({ commit }, id) {
      return Vue.axios.delete(`/custom-csvs/${id}`).then(({ data }) => {
        commit("deleteItem", id);
        return data.success;
      });
    }
  }
};
