<template>
  <div class="text-center last-nav-item">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-list-item v-bind="attrs" v-on="on" class="text-left">
          <v-list-item-icon>
            <v-icon color="error">mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Log Out</v-list-item-title>
        </v-list-item>
      </template>

      <v-card>
        <v-card-title class="headline grey lighten-2">
          Log Out
        </v-card-title>

        <v-card-text class="title text-center pa-7">
          Are you sure?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="dialog = false">
            Cancel
          </v-btn>
          <v-btn color="default" text @click="logout">
            Log Out
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "LogoutMenuModal",
  data() {
    return { dialog: false };
  },
  methods: {
    ...mapActions("auth", ["logout"])
  }
};
</script>

<style scoped>
.last-nav-item {
  margin-bottom: 110px;
}
</style>
