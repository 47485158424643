import Vue from "vue";
import { getField, updateField } from "vuex-map-fields";
import pick from "lodash.pick";

const emptyAddress = () => ({
  address_1: "",
  address_2: "",
  city: "",
  client_id: null,
  cllients_address_type_id: null,
  country_id: null,
  id: null,
  postal_code: "",
  state_id: null
});

const emptyClient = () => ({
  activated: null,
  address_1: "",
  address_2: "",
  balance_imported: null,
  billing_account_only: false,
  billing_address: emptyAddress(),
  city: "",
  clients_addresses: [],
  clients_email_address_id: null,
  clients_email_addresses: [],
  clients_third_party_billing: null,
  country_id: null,
  created: null,
  created_by_o_user_id: null,
  current_account_balance: "0.00",
  deactivated: null,
  fax: "",
  first_name: "",
  footer_o_file_id: null,
  has_custom_footer: false,
  has_custom_header: false,
  header_o_file_id: null,
  home_phone: "",
  id: null,
  invoice_communication_types: [],
  is_active: true,
  is_grand_valley: false,
  is_wallensteins: false,
  lab_id: null,
  last_name: "",
  legacy_account_id: null,
  locale_code: "en_US",
  merge_reports_by_farm: true,
  mobile_phone: "",
  modified: null,
  modified_by_o_user_id: null,
  notes: "",
  payment_hold: false,
  postal_code: "",
  release_full_package: false,
  report_best_mix: true,
  report_calibrate: true,
  report_communication_types: [],
  report_grand_valley: true,
  report_layouts: [],
  report_template_types: [],
  report_templates: [],
  shipping_address: emptyAddress(),
  state_id: null,
  third_party_billing: false,
  title: "",
  title_with_id: "",
  unit_type_id: null,
  work_phone: ""
});

export default {
  namespaced: true,
  state: {
    items: [],
    client: emptyClient(),
    useMainAddress: [],
    invoice_communication_types_selected: [],
    report_layouts_selected: [],
    report_communication_types_selected: [],
    report_templates_selected: [],
    report_template_types_selected: [],
    error: {}
  },
  getters: {
    getField,
    selectedClient(state) {
      let selected = false;
      if (state.client.id) {
        selected = state.items.find(item => item.id === state.client.id);
        if (selected) return state.client;
      }
      const storageId = localStorage.getItem("clientId");
      if (storageId) {
        selected = state.items.find(item => item.id === parseInt(storageId));
        if (selected) return selected;
      }
      return state.items[0] || emptyClient();
    },
    hasMultipleClients(state) {
      return state.items && state.items.length > 1;
    },
    clientLabIds(state) {
      return state.items.map(client => client.lab_id);
    }
  },
  mutations: {
    updateField,
    updateAddressField(state, { field, val, type_ids = [] }) {
      // Update base items
      Vue.set(state.items, 0, {
        ...state.items[0],
        [field]: val
      });
      // Update client addresses with type id in type_ids array
      if (type_ids.length) {
        type_ids.map(type_id => {
          const existingIndex = state.items[0].clients_addresses.findIndex(
            address => address.clients_address_type_id === type_id
          );
          Vue.set(state.items[0].clients_addresses[existingIndex], field, val);
        });
      }
    },
    initUseMainAddress(state, type_ids = []) {
      if (type_ids.length) {
        type_ids.map(type_id => {
          const existingIndex = state.items[0].clients_addresses.findIndex(
            address => address.clients_address_type_id === type_id
          );
          if (existingIndex !== -1) {
            Vue.set(state.items[0].clients_addresses, existingIndex, {
              ...state.items[0].clients_addresses[existingIndex],
              ...pick(state.items[0], [
                "address_1",
                "address_2",
                "city",
                "postal_code",
                "country_id",
                "state_id"
              ])
            });
          }
        });
      }
    },
    setClient(state, client) {
      state.client = client;
      if (client.id) localStorage.setItem("clientId", client.id);
    },
    setItems(state, payload) {
      Vue.set(state, "items", payload);
      // Additional actions for the first item
      if (payload && payload.length) {
        // Update the communication types proxy field, compatible for checkboxes
        Vue.set(
          state,
          "invoice_communication_types_selected",
          state.items[0].invoice_communication_types.map(item => item.id)
        );
        Vue.set(
          state,
          "report_layouts_selected",
          state.items[0].report_layouts.map(item => item.id)
        );
        Vue.set(
          state,
          "report_communication_types_selected",
          state.items[0].report_communication_types.map(item => item.id)
        );
        Vue.set(
          state,
          "report_templates_selected",
          state.items[0].report_templates.map(item => item.id)
        );
        Vue.set(
          state,
          "report_template_types_selected",
          state.items[0].report_template_types.map(item => item.id)
        );
      }
    },
    addEmail(state) {
      const firstItem = { ...state.items[0] };
      firstItem.clients_email_addresses.push({
        email_address: "",
        receive_copy_of_all: false
      });
      Vue.set(state.items, 0, firstItem);
    },
    deleteEmail(state, index) {
      state.items[0].clients_email_addresses.splice(index, 1);
    },
    addAddress(state, addressType) {
      const firstItem = { ...state.items[0] };
      firstItem.clients_addresses.push({
        clients_address_type_id: addressType.id,
        clients_address_type: addressType,
        address_1: "",
        address_2: "",
        city: "",
        country_id: null,
        state_id: null,
        postal_code: ""
      });
      // Make sure addresses are sorted by type
      firstItem.clients_addresses.sort(
        (a, b) => a.clients_address_type_id - b.clients_address_type_id
      );
      Vue.set(state.items, 0, firstItem);
    },
    removeAddress(state, addressTypeId) {
      Vue.set(state.items[0], "clients_addresses", [
        ...state.items[0].clients_addresses.filter(
          address => address.clients_address_type_id !== addressTypeId
        )
      ]);
    },
    addThirdPartyBilling(state, accountId) {
      Vue.set(
        state.items[0],
        "clients_third_party_billing",
        accountId
          ? {
              billing_only_account_number: accountId
            }
          : {}
      );
    },
    setClientStatusId(state, statusId) {
      Vue.set(state.items[0], "clients_status_id", statusId);
    }
  },
  actions: {
    getClients({ commit }) {
      return Vue.axios.get("/clients").then(({ data }) => {
        commit("setItems", data.data);
      });
    },
    updateClient({ getters }, payload) {
      // If there is no first client, nothing to process
      if (getters.selectedClient === undefined) return false;
      return Vue.axios
        .put(`/clients/${getters.selectedClient.id}`, payload)
        .then(res => {
          const { data } = res;
          return data.success || false;
        });
    },
    updateClientContactInfo({ getters, dispatch }) {
      // Create the payload object
      return dispatch(
        "updateClient",
        pick(getters.selectedClient, [
          "work_phone",
          "home_phone",
          "mobile_phone",
          "fax",
          "clients_email_addresses"
        ])
      );
    },
    updateClientAddresses({ getters, dispatch }) {
      // Split client addresses
      getters.selectedClient.clients_addresses.forEach(address => {
        // If Billing Address
        if (address.clients_address_type_id === 1) {
          getters.selectedClient.billing_address = address;
        }
        // If Shipping
        if (address.clients_address_type_id === 2) {
          getters.selectedClient.shipping_address = address;
        }
      });

      return dispatch(
        "updateClient",
        pick(getters.selectedClient, [
          "address_1",
          "address_2",
          "city",
          "postal_code",
          "country_id",
          "state_id",
          "billing_address",
          "shipping_address",
          "third_party_billing",
          "clients_third_party_billing"
        ])
      );
    },
    updateClientInvoiceTypes({ state, getters, commit, dispatch }) {
      // If client status is new, update to pending on this final step of registration.
      if (getters.selectedClient.clients_status_id === 1) {
        commit("setClientStatusId", 4); // 4 = pending status
      }

      return dispatch("updateClient", {
        clients_status_id: getters.selectedClient.clients_status_id,
        // Map array of IDs to array of objects [{ id: value }]
        invoice_communication_types: state.invoice_communication_types_selected.map(
          id => {
            return { id };
          }
        ),
        report_templates_selected: state.report_templates_selected.map(id => {
          return { id };
        }),
        report_template_types_selected: state.report_template_types_selected.map(
          id => {
            return { id };
          }
        )
      });
    },
    updateClientLab({ getters, dispatch }) {
      return dispatch("updateClient", pick(getters.selectedClient, ["lab_id"]));
    },
    updateReportingPreferences({ state, getters, dispatch }) {
      return dispatch("updateClient", {
        unit_type_id: getters.selectedClient.unit_type_id,
        locale_code: getters.selectedClient.locale_code,
        release_full_package: getters.selectedClient.release_full_package,
        merge_reports_by_farm: getters.selectedClient.merge_reports_by_farm,
        // Map array of IDs to array of objects [{ id: value }]
        report_layouts: state.report_layouts_selected.map(id => {
          return { id };
        }),
        report_communication_types: state.report_communication_types_selected.map(
          id => {
            return { id };
          }
        ),
        report_templates: state.report_templates_selected.map(id => {
          return { id };
        }),
        report_template_types: state.report_template_types_selected.map(id => {
          return { id };
        })
      });
    },
    getBillingOnlyClientId({ commit }, search) {
      return Vue.axios
        .get("/clients/validate-billing-account", {
          params: {
            search: search
          }
        })
        .then(({ data }) => {
          commit("addThirdPartyBilling", data.data.id);
          return data.success || false;
        })
        .catch(e => {
          console.error(e);
          return false;
        });
    },
    getThirdPartyBillingClients() {
      return Vue.axios
        .get("/third_party_billing_accounts", {
          params: { account_number: 13 }
        })
        .then(({ data }) => {
          console.log(data);
          //commit("setItems", data.data);
        });
    },
    initClientAddresses({ state, getters, rootGetters, commit }) {
      const billingTypeId = (
        rootGetters["baseData/getById"](
          "clients_address_types",
          "Billing",
          "title"
        ) || {}
      ).id;
      // Loop through each address type, and take necessary actions
      rootGetters["baseData/clients_address_types"].forEach(addressType => {
        const exists = getters.selectedClient.clients_addresses.find(
          address => address.clients_address_type_id === addressType.id
        );

        // Billing address actions
        if (addressType.id === billingTypeId) {
          // If third party billing is enabled, we need to make sure the address is removed
          if (getters.selectedClient.third_party_billing) {
            if (exists) {
              commit("removeAddress", exists.clients_address_type_id);
            }
            // Return now, since we don't want to add the address below.
            return true;
          }
        }

        if (!exists) {
          commit("addAddress", addressType);
        }
      });

      commit("initUseMainAddress", state.useMainAddress);
    },
    updateAccountInfo({ getters, dispatch }) {
      // Create the payload object
      return dispatch(
        "updateClient",
        pick(getters.selectedClient, ["title", "first_name", "last_name"])
      );
    }
  }
};
