import Vue from "vue";
import Vuex from "vuex";
import state from "@/store/state";
import actions from "@/store/actions";
import mutations from "@/store/mutations";
import modules from "@/store/modules/index";
import getters from "@/store/getters";

Vue.use(Vuex);

const store = new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules
});

Vue.store = store;

export default store;
