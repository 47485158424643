import Vue from "vue";
import { getField, updateField } from "vuex-map-fields";
import { getPaginated } from "@/utils/api-utils";

export default {
  namespaced: true,
  state: {
    invoices: []
  },
  getters: {
    getField
  },
  mutations: {
    updateField,
    setInvoices(state, payload) {
      Vue.set(state, "invoices", payload);
    }
    // updateAccountApproved(state, payload) {
    //   Vue.set(state.accounts, payload.index, payload.item);
    // }
  },
  actions: {
    getPaginated: getPaginated("/invoices"),
    getInvoices({ commit, dispatch }) {
      return dispatch("getPaginated", { itemsPerPage: 100 }).then(
        ({ data }) => {
          commit("setInvoices", data.data);
        }
      );
    },
    getInvoice(context, payload) {
      const config = {
        params: {
          render: "pdf"
        },
        responseType: "blob",
        transformRequest: [
          function(data, headers) {
            // Remove the content type headers so any type is accepted
            delete headers.common["Content-Type"];
            delete headers.common["Accept"];
            return data;
          }
        ]
      };
      return Vue.axios
        .get("invoice-pdfs/" + payload.invoice_id, config)
        .then(res => {
          // Return the PDF blob
          return res.data;
        });
    },
    downloadInvoice({ dispatch }, payload) {
      // Fetch the invoice
      return dispatch("getInvoice", payload).then(invoice => {
        // Trigger download of the file
        let a = document.createElement("a");
        a.href = URL.createObjectURL(invoice);
        // Set the download filename
        a.setAttribute("download", "Invoice");
        a.click();
        // Cleanup
        setTimeout(() => a.remove(), 500);
      });
    }
  }
};
