// import Vue from "vue";
import { getField, updateField } from "vuex-map-fields";

const defaultState = () => {
  return {
    wizard: {
      currentStep: 1,
      returnStep: false, // Next step override
      steps: [
        {
          title: "Account Information",
          component: "AccountInformationStep"
        },
        {
          // The title that shown in the stepper
          title: "Contact Information",
          // Define component name with the exact variable name we are importing to
          component: "ContactInformationStep"
        },
        {
          title: "Address",
          component: "AddressStep"
        },
        {
          title: "Lab Selection",
          component: "LabSelectionStep"
        },
        {
          title: "Reporting Preferences",
          component: "ReportingPreferencesStep"
        },
        {
          title: "Invoice Terms",
          component: "InvoiceTermsStep"
        },
        {
          title: "Review",
          component: "ReviewStep"
        }
      ]
    }
  };
};

export default {
  namespaced: true,
  state: defaultState(),
  getters: {
    getField,
    steps(state, getters, rootState, rootGetters) {
      return state.wizard.steps.filter(step => {
        if (step.component === "InvoiceTermsStep") {
          if (rootGetters["clients/selectedClient"].third_party_billing) {
            return false;
          }
        }
        return true;
      });
    },
    stepId(state, getters) {
      return component_name => {
        return (
          getters.steps.findIndex(step => step.component === component_name) +
            1 || getters.steps.length - 1 + 1
        );
      };
    }
  },
  mutations: {
    updateField,
    initializeState(state) {
      Object.assign(state, defaultState());
    }
  }
};
