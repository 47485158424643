export const secureRoute = (route, override = null) => {
  // Give each route a meta object, if it doesn't have one already
  if (route.meta === undefined) route.meta = {};
  // Secure all top level routes by default
  // If auth wasn't set, default to "client"
  if (route.meta.auth === undefined) {
    route.meta.auth = override !== null ? override : ["client"];
  }
  // If auth is set to 2, then everyone can access the route
  if (route.meta.auth === 2) {
    route.meta.auth = undefined;
  }
  // Recursively run this function against children
  if (route.children && route.children.length) {
    // Children should get the same privilege as the parent
    route.children = route.children.map(child =>
      secureRoute(child, route.meta.auth)
    );
  }
  return route;
};

export default secureRoute;
