import axios from "axios";
import { jsonapiModule } from "jsonapi-vuex";
import { getBaseUrl } from "@/utils";
import auth from "./auth";
import baseData from "./baseData";
import registration from "./registration";
import clients from "./clients";
import copyTo from "./copy_to";
import billingAccounts from "./billing_accounts";
import samples from "./samples";
import sampleWizard from "./samples/sample-wizard";
import shipments from "./shipments";
import farms from "./farms/index";
import sources from "./sources/index";
import csvs from "./csvs/index";
import reports from "./reports";
import invoices from "./invoices";
import data_review from "./data_review";

const jsonApi = axios.create({
  baseURL: `${getBaseUrl()}/`,
  headers: {
    Accept: "application/vnd.api+json",
    "Content-Type": "application/vnd.api+json"
  }
});

export default {
  jv: jsonapiModule(jsonApi),
  auth,
  baseData,
  registration,
  clients,
  copyTo,
  samples,
  shipments,
  billingAccounts,
  sampleWizard,
  farms,
  sources,
  csvs,
  reports,
  invoices,
  data_review
};
