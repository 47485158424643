<template>
  <v-app>
    <template v-if="!focused">
      <NavigationDrawer />
      <AppBar />
    </template>

    <v-main>
      <v-container fluid>
        <transition name="fade-in">
          <router-view
            v-if="
              (!loading.status && !loading.initial) || loading.overlay === true
            "
          ></router-view>
        </transition>
      </v-container>
    </v-main>

    <SnackBar />

    <v-overlay
      :value="loading.status"
      :opacity="loading.opacity"
      class="text-center"
    >
      <v-progress-circular
        indeterminate
        size="64"
        color="primary"
        class="mb-5"
        :width="7"
      ></v-progress-circular>
      <p class="text-h6">
        {{ !!loading.message ? loading.message : "Loading..." }}
      </p>
    </v-overlay>

    <v-footer v-if="!focused" app> </v-footer>
  </v-app>
</template>

<script>
import AppBar from "@/components/layout/AppBar";
import NavigationDrawer from "@/components/layout/NavigationDrawer";
import SnackBar from "@/components/_app/SnackBar";
import { mapState } from "vuex";

export default {
  name: "App",
  components: { AppBar, NavigationDrawer, SnackBar },
  data: () => ({}),
  computed: {
    ...mapState(["loading"]),
    focused() {
      if (!this.$auth.check()) return true;
      if (this.loading.status && !this.loading.overlay) return true;
      return !!this.$route.meta.focused;
    }
  }
};
</script>

<style lang="scss">
.fade-in-enter-active {
  transition: opacity 0.8s;
}
.fade-in-leave-active {
  transition: opacity 0s;
}
.fade-in-enter,
.fade-in-leave-to {
  opacity: 0;
}
</style>
