<template>
  <v-app-bar color="primary" dark app class="app-bar-gradient">
    <v-app-bar-nav-icon
      @click.stop="drawerOpen = !drawerOpen"
    ></v-app-bar-nav-icon>
    <v-app-bar-title>
      <div id="app_bar_title">CVAS AgriLIMS</div>
    </v-app-bar-title>
    <v-spacer></v-spacer>
    <Toolbox v-if="developmentMode" />
  </v-app-bar>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import Toolbox from "@/components/_dev/Toolbox";

export default {
  name: "AppBar",
  components: { Toolbox },
  computed: {
    ...mapFields(["navigation.drawerOpen"]),
    developmentMode() {
      return process && process.env && process.env.NODE_ENV === "development";
    }
  }
};
</script>

<style scoped>
.app-bar-gradient {
  background: rgb(22, 105, 65);
  background: linear-gradient(90deg, rgb(22, 105, 65) 0%, rgb(11, 55, 34) 100%);
}
</style>
