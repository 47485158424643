<template>
  <v-card class="mt-5">
    <v-card-title>My Testing Statistics</v-card-title>
    <v-card-text>
      <v-simple-table>
        <tbody>
          <tr>
            <th>Samples Pending</th>
            <td v-if="isLoading">
              <v-progress-linear indeterminate />
            </td>
            <td v-else>
              <router-link to="/samples/browse?is_pending=true">{{
                stats.pending
              }}</router-link>
            </td>
          </tr>
          <tr>
            <th>Samples in Transit</th>
            <td v-if="isLoading">
              <v-progress-linear indeterminate />
            </td>
            <td v-else>
              <router-link to="/shipments/browse?in_transit=true">{{
                stats.in_transit
              }}</router-link>
            </td>
          </tr>
          <tr>
            <th>Reported Today</th>
            <td v-if="isLoading">
              <v-progress-linear indeterminate />
            </td>
            <td v-else>
              <router-link to="/samples/browse?created_today=true">{{
                stats.reported_today
              }}</router-link>
            </td>
          </tr>
          <tr>
            <th>Average NIR Return Time</th>
            <td v-if="isLoading">
              <v-progress-linear indeterminate />
            </td>
            <td v-else>{{ average_nir_return_days }}</td>
          </tr>
          <tr>
            <th>Average Transit Time</th>
            <td v-if="isLoading">
              <v-progress-linear indeterminate />
            </td>
            <td v-else>{{ average_transit_days }}</td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "TestingStatistics",
  data: () => ({
    isLoading: true
  }),
  computed: {
    ...mapState("samples", ["stats"]),
    average_transit_days() {
      return !this.stats || this.stats.average_transit_days === null
        ? "NA"
        : this.stats.average_transit_days +
            " day" +
            (this.stats.average_transit_days === 1 ? "" : "s");
    },
    average_nir_return_days() {
      return !this.stats || this.stats.average_nir_return_days === null
        ? "NA"
        : this.stats.average_nir_return_days +
            " day" +
            (this.stats.average_nir_return_days === 1 ? "" : "s");
    }
  },
  methods: {
    ...mapActions("samples", ["getStats"])
  },
  async mounted() {
    this.isLoading = true;
    await this.getStats();
    this.isLoading = false;
  }
};
</script>

<style scoped></style>
