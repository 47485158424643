import Vue from "vue";

export default {
  request: function(req, token) {
    if (req.url === this.options.refreshData.url) {
      // Use Refresh token for refresh requests
      token = this.token(this.options.refreshTokenName);
      req.data = { refresh_token: token };
    }

    let accept = "application/json";
    let contentType = "application/json";
    // Allow override of header
    if (req.transformHeader) {
      accept = req.transformHeader;
      contentType = req.transformHeader;
    }

    this.drivers.http.setHeaders.call(this, req, {
      "Content-Type": contentType,
      Accept: accept,
      Authorization: token
    });
  },
  response: function(res) {
    const data = res.data || {};
    this.token(this.options.refreshTokenName, data.refresh);

    if (res.config.url === this.options.fetchData.url) {
      /**
       * This is a auth.fetch() (get user) response
       * This runs on page load, right after initial authentication (including page reloads)
       */
      // Set user data from response
      Vue.store.commit("auth/SET_USER_DATA", data.data || {});
      // Load "base data"
      Vue.store.dispatch("afterLogin").catch(console.error);
    }
    return data.access;
  }
};
