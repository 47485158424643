<template>
  <v-dialog v-model="dialog" persistent max-width="600px" min-width="360px">
    <div>
      <v-tabs
        v-model="activeTab"
        show-arrows
        background-color="primary"
        icons-and-text
        dark
        grow
      >
        <v-tabs-slider color="primary darken-4"></v-tabs-slider>

        <v-tab>
          <v-icon large>mdi-account</v-icon>
          <div class="caption py-1">Login</div>
        </v-tab>

        <v-tab>
          <v-icon large>mdi-account-outline</v-icon>
          <div class="caption py-1">Register</div>
        </v-tab>

        <v-tab-item>
          <div v-if="error !== false" class="error lighten-1">
            <p class="pa-5 text-center" v-html="error"></p>
          </div>
          <div v-if="message !== false" class="success">
            <p class="pa-5 text-center" v-html="message"></p>
          </div>
          <v-card class="px-4">
            <v-card-text>
              <v-form ref="loginForm" v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      name="username"
                      v-model="username"
                      :rules="rules.email"
                      label="Email"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      block
                      v-model="password"
                      v-on:keyup.enter="submit"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="rules.password"
                      :type="showPassword ? 'text' : 'password'"
                      name="password"
                      label="Password"
                      hint="At least 8 characters"
                      counter
                      @click:append="showPassword = !showPassword"
                    ></v-text-field>
                  </v-col>
                  <v-col class="d-flex" cols="12" sm="6" xsm="12"></v-col>
                  <v-spacer></v-spacer>
                  <v-col class="text-center" cols="12" sm="6">
                    <v-btn
                      x-large
                      block
                      :disabled="!valid"
                      color="primary"
                      @click="submit"
                    >
                      Login
                    </v-btn>
                    <router-link
                      to="/register/password-reset"
                      class="d-block mt-2"
                    >
                      Reset Password
                    </router-link>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <Register />
        </v-tab-item>
      </v-tabs>
    </div>
  </v-dialog>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { mapState, mapActions } from "vuex";
import Register from "@/components/auth/Register";
import rules from "@/utils/rules";

export default {
  name: "LoginComponent",
  components: { Register },
  data() {
    return {
      dialog: true,
      showPassword: false,
      tab: null,
      valid: false,
      rules: {
        password: [rules.required, rules.minLength(8)],
        email: [rules.required, rules.email]
      }
    };
  },
  computed: {
    ...mapState("auth", ["registration"]),
    ...mapFields("auth", [
      "username",
      "password",
      "error",
      "activeTab",
      "message"
    ])
  },
  methods: {
    ...mapActions("auth", ["login"]),
    submit() {
      if (this.$refs.loginForm.validate()) {
        this.login();
      }
    }
  }
};
</script>

<style scoped></style>
