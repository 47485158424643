export const required = v => !!v || "Required";

export const minLength = length => {
  return v => (v && v.length >= length) || `Min ${length} characters`;
};

export const maxLength = length => {
  return v => {
    if (!v || v.length === 0) {
      return true;
    }
    return (v && v.length <= length) || `Max ${length} characters`;
  };
};

export const minNum = num => {
  return v => num <= v || `Min value: ${num}`;
};

export const maxNum = num => {
  return v => num >= v || `Max value: ${num}`;
};

export const betweenNums = (low, high) => {
  return v => (low <= v && high >= v) || `Must be between: ${low} and ${high}`;
};

export const email = v => {
  if (v.length === 0) {
    return true;
  }
  return /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/.test(v) || "Email must be valid";
};

export const password = v => {
  if (required(v) !== true) return "Password is required";
  // Check length
  let len = minLength(8)(v);
  // Pass along the message if it isn't valid
  if (len !== true) return len;
  // Must contain at least one uppercase
  if (v === v.toLowerCase())
    return "Password must contain an uppercase character";
  // Must contain at least one lowercase
  if (v === v.toUpperCase())
    return "Password must contain an lowercase character";
  return true;
};

export const alphanumeric = v =>
  /^[a-z\d\s]+$/i.test(v) || "Must be alphanumeric";

export const numeric = v =>
  !v || v.length === 0 || /^(0|[1-9][0-9]*)$/.test(v) || "Must be numeric";

export const alphabetic = v =>
  !v || v.length === 0 || /^[a-zA-Z\s]*$/g.test(v) || "Must be only letters";

export default {
  required,
  minLength,
  maxLength,
  minNum,
  maxNum,
  betweenNums,
  email,
  password,
  alphanumeric,
  numeric,
  alphabetic
};
